body {
  z-index: 0;
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  overflow: hidden;
  font-family: 'Roboto', 'Arial';
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input {
  -webkit-user-select: text;
  user-select: text;
  font-family: 'Roboto', 'Arial';
}

select {
  font-family: 'Roboto', 'Arial';
}

option {
  font-size: inherit;
  font-family: 'Roboto', 'Arial';
  color: inherit;
}

div, label, span, form, input {
  box-sizing: border-box;
  font-family: 'Roboto', 'Arial';
}

p:hover {
  cursor: default;
}

a {
  color: inherit;
  text-decoration: inherit;
}

a:focus {
  outline: none;
}

button::-moz-focus-inner {
  border: 0;
}

.checkbox:hover {
  cursor: pointer;
}

select:focus {
  outline: none;
}

.material-icons,
.material-icons-outlined {
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
  color: inherit;
  font-size: inherit;
  user-select: none;
}

/* Navigation */
.material-icons.threeDots {
  font-size: 60px;
}
.material-icons.threeDots:hover {
  font-size: 64px;
  margin-left: -4px;
  cursor: pointer;
}

.material-icons.back {
  font-size: 30px;
}
.material-icons.back:hover {
  font-size: 34px;
  margin-right: -4px;
  cursor: pointer;
}

.material-icons.back_small {
  font-size: 20px;
  padding: 10px 10px;
}

.material-icons.search {
  font-size: 26px;
  margin-right: -6px;
  margin-top: 4px;
}
.material-icons.search:hover {
  font-size: 28px;
  margin-right: -8px;
  cursor: pointer;
}

.material-icons.search_small {
  font-size: 18px;
  margin-right: -6px;
  margin-top: 4px;
}

/* AlbumView */

.material-icons.selectAll {
  font-size: 30px;
}
.material-icons.selectAll:hover {
  cursor: pointer;
  font-size: 32px;
  margin-left: -1px;
}

.material-icons.clearSelection {
  font-size: 30px;
}
.material-icons.clearSelection:hover {
  cursor: pointer;
  font-size: 32px;
  margin-right: -1px;
}

/* PhotoView */
.material-icons.next {
  font-size: 50px;
}
.material-icons.next:hover {
  font-size: 54px;
  cursor: pointer;
}

.material-icons.previous {
  font-size: 50px;
}
.material-icons.previous:hover {
  font-size: 54px;
  cursor: pointer;
}

.material-icons.submit-PhotoView {
  font-size: 28px;
  margin-left: 15px;
}

.material-icons.material-icons.submit-PhotoView:hover {
  font-size: 30px;
  margin-left: 14px;
  margin-top: -2px;
  cursor: pointer;
}

/* PhotoView Toolbar */
.material-icons.download-PhotoView {
  font-size: 44px;
}
.material-icons.download-PhotoView:hover {
  font-size: 46px;
  margin-right: -2px;
  cursor: pointer;
}

.material-icons.thumb {
  font-size: 28px;
}
.material-icons.thumb:hover {
  font-size: 30px;
  margin-left: -1px;
  margin-right: -1px;
  cursor: pointer;
}

/* PhotoToolbar */
.material-icons.download-PhotoToolbar {
  font-size: 30px;
}
.material-icons.download-PhotoToolbar:hover {
  font-size: 32px;
  margin-right: -2px;
  cursor: pointer;
}

.material-icons.thumb-PhotoToolbar {
  font-size: 24px;
}
.material-icons.thumb-PhotoToolbar:hover {
  font-size: 26px;
  margin-right: -2px;
  cursor: pointer;
}

/* Upload */
.material-icons.upload-Add {
  font-size: inherit;
}
.material-icons.upload-Add:hover {
  font-size: inherit;
}
.material-icons.upload-Clear {
  font-size: inherit;
}
.material-icons.upload-Upload {
  font-size: inherit;
}
.material-icons.upload-Delete {
  font-size: inherit;
}
.material-icons.upload-Delete:hover {
  font-size: inherit;
}

/* Search */
.material-icons.add-filter {
  font-size: 28px;
}

.material-icons.remove-filter {
  font-size: 30px;
  margin: 16px 0px 0px 24px;
}
.material-icons.remove-filter:hover {
  cursor: pointer;
  font-size: 32px;
  margin-left: 23px;
}

/* PendingFormItems */
.material-icons.pendingFormItems-reject {
  font-size: 30px;
  color: inherit;
}
.material-icons.pendingFormItems-reject:hover {
  font-size: 32px;
  cursor: pointer;
}

.material-icons.pendingFormItems-accept {
  font-size: 30px;
  color: inherit;
}
.material-icons.pendingFormItems-accept:hover {
  font-size: 32px;
  cursor: pointer;
}

/* TagSuggestions */
.material-icons.tagSuggestions-reject {
  font-size: 30px;
  color: inherit;
}
.material-icons.tagSuggestions-reject:hover {
  font-size: 32px;
  cursor: pointer;
}

.material-icons.tagSuggestions-accept {
  font-size: 30px;
  color: inherit;
}
.material-icons.tagSuggestions-accept:hover {
  font-size: 32px;
  cursor: pointer;
}

/* Analytics */
.material-icons.analytics-sortUp {
  font-size: 45px;
  color: inherit;
}
.material-icons.analytics-sortUp:hover {
  font-size: 49px;
  margin: -4px -1px -10px -2px;
  cursor: pointer;
}

.material-icons.analytics-sortDown {
  font-size: 45px;
  color: inherit;
}
.material-icons.analytics-sortDown:hover {
  font-size: 49px;
  margin: -4px -1px -10px -2px;
  cursor: pointer;
}

/* Bookings Small */

.material-icons-outlined.bookingCard-info-small {
  font-size: 24px;
  margin-left: 8px;
  color: inherit;
}

.material-icons-outlined.bookingCard-info-small:hover {
  font-size: 26px;
  margin: -1px 0px -1px 6px;
  cursor: pointer;
}

.material-icons.bookingCard-info-small {
  font-size: 24px;
  margin-left: 8px;
  color: inherit;
}

.material-icons.bookingCard-info-small:hover {
  font-size: 26px;
  margin: -1px 0px -1px 6px;
  cursor: pointer;
}

.material-icons.bookingCard-edit-small {
  font-size: 24px;
  margin-left: 8px;
  color: inherit;
}

.material-icons.bookingCard-edit-small:hover {
  font-size: 26px;
  margin-left: 6px;
  cursor: pointer;
}

.material-icons.bookingCard-delete-small {
  font-size: 24px;
  color: inherit;
}

.material-icons.bookingCard-delete-small:hover {
  font-size: 26px;
  cursor: pointer;
}

.material-icons.bookingCard-decline-small {
  font-size: 24px;
  margin-left: 6px;
  margin-right: 20px;
  color: inherit;
}

.material-icons.bookingCard-decline-small:hover {
  font-size: 26px;
  margin: -2px 20px 0px 4px;
  cursor: pointer;
}

.material-icons.bookingCard-accept-small {
  font-size: 24px;
  color: inherit;
}

.material-icons.bookingCard-accept-small:hover {
  font-size: 26px;
  margin-top: -2px;
  cursor: pointer;
}

.material-icons.bookingCard-type-small {
  font-size: 22px;
  color: inherit;
}

.material-icons.bookings-add {
  font-size: 40px;
  color: inherit;
}

/* Shared */
.material-icons.filter {
  color: inherit;
  font-size: inherit;
}

.material-icons.arrowDropDown {
  color: inherit;
}

.material-icons.sort {
  font-size: 24px;
  margin-right: 6px;
  color: inherit;
}

.material-icons.sort-small {
  font-size: 20px;
  margin-right: 6px;
  color: inherit;
}

.material-icons.filter {
  font-size: 24px;
  margin-right: 6px;
  color: inherit;
}

.material-icons.filter-small {
  font-size: 20px;
  margin-right: 6px;
  color: inherit;
}

.material-icons-outlined.filter-checkbox-true {
  font-size: 20px;
  color: inherit;
}

.material-icons-outlined.filter-checkbox-true-small {
  font-size: 20px;
  color: inherit;
}

.material-icons-outlined.filter-checkbox-false {
  font-size: 20px;
  color: inherit;
}

.material-icons-outlined.filter-checkbox-false-small {
  font-size: 20px;
  color: inherit;
}

.material-icons.filter-small {
  font-size: 20px;
  margin-right: 6px;
  color: inherit;
}

.material-icons.radio {
  font-size: 16px;
  margin-right: 4px;
  color: inherit;
}
